import React from 'react';
import MCPageHeader from '../../../components/magicrop/MCPageHeader';
import { Helmet } from "react-helmet"

function Magicrop(props) {
    

    return <div className="magicrop-body">
        <Helmet>
            <meta charSet="utf-8" />
            <title>How to Resize a Video to Right Aspect Ratio and Size</title>
            <link rel="canonical" href="https://fabio.santo.dev/magicrop-dynamic-cropping-video-editor-mac-software" />
            </Helmet>
            <MCPageHeader>
                <div className="container p-10">
                    <div className="font-bold text-4xl">How to Resize a Video to Right Aspect Ratio and Size</div>
                    <p>You have a video in horizontal 16:9 aspect ratio, but you want to share it to social network like Instagram, Facebook, Youtube, TikTok or Twitter?</p>
                    <p>Each platform has its own ideal aspect ratio, and it's best for you and for your audience to share a video which uses the full screen real estate.</p>
                    <p>Most video editing software allow you to crop a video to a given aspect ratio, but in most cases the area which of the video which will be cropped is fixed for the entire duration of the video.</p>
                    <p><b>Magicrop</b> is a new app for iPad and Mac which finally solves this problem, allowing you to track the subject making your video interesting</p>
                    <p>1:1 for Instagram posts, 16:9 for generic full screen mobile screen size are just some of the many format you can export your video to.</p>
                    <p>Additionally with Magicrop it's easy to track subjects in motion like a surfer riding a wave. Instead of cutting out the subject out of the frame simply drag and drop the red frame following your subject.</p>
                    <p>Magicrop will crop your video following the movement of your subject.</p>
                    <p>You can download Magicrop for FREE from the iPad App Store or from the Mac App Store</p>
                </div>
            </MCPageHeader>
        </div>
}

export default Magicrop